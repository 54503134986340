import Parser from 'html-react-parser';

/**
 * Get data from datalayer dealership with a given key
 * Returns a string with default value of empty string
 * @param {string} key - A string value used for search for a value in dealership object
 */
const getDealershipData = (key) => {
	const { window } = global;
	if (
		typeof window !== 'undefined' &&
		window.DDC &&
		window.DDC.dataLayer &&
		window.DDC.dataLayer.dealership
	) {
		return window.DDC.dataLayer.dealership[key];
	}
	return '';
};

const patternKeyMap = {
	'{{city}}': () => getDealershipData('city'),
	'{{dealer}}': () => getDealershipData('dealershipName'),
	'{{address}}': () => getDealershipData('address1'),
	'{{phone}}': () => getDealershipData('phone1')
};

/**
 * Takes the mapValue and execute it if is a function
 * Returns a string with default value of the str param
 * @param {string|function} mapValue - a function which return an string or an string, to be used as return value
 * @param {string} str - string value to be used if there is not mapValue
 */
const getReplacement = (mapValue, str) => {
	if (mapValue) {
		if (typeof mapValue === 'function') {
			return mapValue();
		} else {
			return mapValue;
		}
	}
	return str;
};
/**
 * Get a text and apply a regex to find a pattern and get a the replacement from a key value object
 * Returns a string with the new text after being modified
 * @param {RegExp} regex - a RegExp to be used as a pattern finder
 * @param {Object} replacerMap - an Object which contain the pattern as the key and the value is the way to get the replacement
 * @param {string} text - an string to modify
 */
export const patternReplacer = (regex, replacerMap, text) => {
	return text
		.split(regex)
		.map((str) => getReplacement(replacerMap[str], str))
		.join('');
};

/**
 * Find "{{}}" pattern in a text and replace it using the patternKeyMap dictionary
 * Returns a string with the new text after being modified
 * @param {string} text - an string to modify
 */
export const dataLayerReplacer = (text) =>
	patternReplacer(/({{[a-z_0-9]+}})/gi, patternKeyMap, text);

/**
 * Takes a text applies a function to transform it and then a function to parse it
 * Returns a value with the result of apply the functions to it
 * @param {function} parser - a function to parse the result of the transform function
 * @param {function} transformFn - a fuction to transform the text param
 * @param {string} text - an string to be transformed and parsed
 */
export const parseTransformedText = (parser, transformFn, text) =>
	parser(transformFn(text));

/**
 * Takes a text applies the datalayerReplacer function and then Parse it as html
 * Returns a value with the result of apply the Parser
 * @param {string} text - an string to be transformed and parsed
 */
export const getHtmlWithDLData = (text) =>
	parseTransformedText(Parser, dataLayerReplacer, text);
