import { SET_ACCORDION_CONTENT } from '../actions/types';

export default function items(state = {}, action) {
	if (!action.error && action.payload !== undefined) {
		switch (action.type) {
			case SET_ACCORDION_CONTENT:
				return action.payload;
			default:
				return state;
		}
	}

	return state;
}
