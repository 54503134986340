import React from 'react';
import PropTypes from 'prop-types';
import { useLabels, usePrefs } from 'ws-scripts/modules/common';
import AccordionItem from './AccordionItem';

const AccordionContent = ({ items }) => {
	const prefs = usePrefs();
	const labels = useLabels();

	const renderItems = React.useCallback(() => {
		return React.Children.toArray(
			items.map((item, index) => (
				<AccordionItem
					item={item}
					defaultExpandIcon={prefs.expandIcon === 'expand'}
					defaultCollapseIcon={prefs.collapseIcon === 'collapse'}
					iconExpand={
						prefs.expandIcon !== 'BLANK' && prefs.expandIcon !== ''
					}
					iconCollapse={
						prefs.collapseIcon !== 'BLANK' &&
						prefs.collapseIcon !== ''
					}
					showExpandedFirstItemByDefault={
						index === 0 && prefs.showFirstItemExpanded === 'true'
					}
				/>
			))
		);
	}, [
		items,
		prefs.expandIcon,
		prefs.collapseIcon,
		prefs.showFirstItemExpanded
	]);

	return (
		<div
			data-testid="accordion-content"
			className="accordion-content d-flex flex-column"
		>
			{labels.getHTML(prefs.accordionBody)}
			<div className="accordion-items">{renderItems()}</div>
		</div>
	);
};

AccordionContent.propTypes = {
	items: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string,
			content: PropTypes.string,
			imageSrc: PropTypes.string,
			imageAlignment: PropTypes.string,
			imageAriaLabel: PropTypes.string
		})
	).isRequired
};

export default AccordionContent;
