import { useSelector } from 'react-redux';
import { useFlags } from 'wsm-common-data';
import React, { useEffect, useState } from 'react';
import { reportToNewRelic } from '../utils/monitoring';
import { LATENCY_TRACKING_SECRET } from '../global-constants';

import AccordionContent from '../components/AccordionContent';

const WidgetContainer = () => {
	const items = useSelector((state) => state.items);
	const flags = useFlags();

	const [timing, setTiming] = useState(false);

	const latencyTracking = {
		eventName: 'wsAccordionContentLatencyTracking',
		eventStart: 0,
		totalRenderTime: 0
	};

	if (!timing) {
		latencyTracking.eventStart = Date.now();
	}

	useEffect(() => {
		setTiming(true);
		latencyTracking.totalRenderTime =
			Date.now() - latencyTracking.eventStart;

		// Reporting to new relic here as this functions similarly to componentDidMount
		if (flags[LATENCY_TRACKING_SECRET]) {
			reportToNewRelic(latencyTracking.eventName, latencyTracking);

			// Additional reporting for item list size
			const itemListSizeReport = {
				eventName: 'wsAccordionContentItemListSize',
				itemListSize: items.length
			};

			reportToNewRelic(itemListSizeReport.eventName, itemListSizeReport);
		}
	}, []);

	return <AccordionContent {...{ items }} />;
};

export default WidgetContainer;
