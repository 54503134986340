import React from 'react';
import PropTypes from 'prop-types';
import { trackEvent } from 'ddc-track-event';
import { setClassNames } from 'ddc-classnames-js';
import { PanelGroup, Panel } from 'react-bootstrap';
import { useRequestData, useLabels, usePrefs } from 'ws-scripts/modules/common';
import { getHtmlWithDLData } from '../utils/helpers/text';

import '../global-sass/main.scss';
import '../global-sass/accordion-item.scss';

const alignmentClassesDict = {
	top: ['flex-column-reverse', 'w-100 pt-3', 'w-100'],
	right: ['flex-row', 'w-50 pr-3', 'w-50'],
	bottom: ['flex-column', 'w-100 pb-3', 'w-100'],
	left: ['flex-row-reverse', 'w-50 pl-3', 'w-50'],
	default: ['flex-row', 'w-100']
};

const AccordionItem = ({
	iconExpand,
	iconCollapse,
	defaultExpandIcon,
	defaultCollapseIcon,
	showExpandedFirstItemByDefault,
	item: { title, content, imageSrc, imageAlignment, imageAriaLabel }
}) => {
	const prefs = usePrefs();
	const labels = useLabels();
	const { widgetName, windowId } = useRequestData();

	const [expanded, setExpanded] = React.useState(
		showExpandedFirstItemByDefault
	);

	const accordionItemClasses = setClassNames([
		'accordion-item',
		prefs.itemClasses
	]);

	const toggleExpanded = React.useCallback(() => {
		const result = expanded
			? 'Accordion Info collapsed'
			: 'Accordion Info expanded';
		trackEvent(widgetName, windowId, {
			result,
			action: 'clicked',
			element: 'Accordion',
			elementCTA: labels.get(title)
		});

		setExpanded(!expanded);
	}, [expanded, widgetName, windowId, title, labels]);

	const icon = () => {
		let classExpand = '';
		let classCollapse = '';
		const baseClasses = 'ddc-icon icon-style-collapse d-inline mr-3 ';

		if (iconCollapse && defaultCollapseIcon) {
			classCollapse = `${baseClasses} ddc-icon-${prefs.collapseIcon}`;
		} else {
			classCollapse = `icon-style-border-none ${baseClasses} ddc-icon-${prefs.collapseIcon}`;
		}
		if (iconExpand && defaultExpandIcon) {
			classExpand = `${baseClasses} ddc-icon-${prefs.expandIcon}`;
		} else {
			classExpand = `icon-style-border-none ${baseClasses} ddc-icon-${prefs.expandIcon}`;
		}
		return expanded ? classCollapse : classExpand;
	};

	const [bodyWrapperClass, bodyContentClass, bodyImageClass] =
		React.useMemo(() => {
			if (imageSrc) {
				return (
					alignmentClassesDict[imageAlignment] ??
					alignmentClassesDict.right
				);
			} else {
				return alignmentClassesDict.default;
			}
		}, [imageSrc, imageAlignment]);

	const labelAsHTML = (label, Tag = React.Fragment) => {
		const html = getHtmlWithDLData(label);
		return <Tag>{html}</Tag>;
	};

	return (
		<div
			data-testid="accordion-content-item"
			className={accordionItemClasses}
		>
			<div>
				<PanelGroup className="my-2" id={title}>
					<Panel
						onToggle={toggleExpanded}
						defaultExpanded={showExpandedFirstItemByDefault}
					>
						<Panel.Heading>
							<Panel.Title toggle>
								<h4
									className={setClassNames([
										prefs.panelHeadingStyle
									])}
								>
									<i
										data-testid="collapsable-icon"
										className={icon()}
									/>
									<span>
										{labelAsHTML(labels.get(title))}
									</span>
								</h4>
							</Panel.Title>
						</Panel.Heading>
						<Panel.Collapse
							className={setClassNames([
								prefs.panelBodyWrapperStyle
							])}
						>
							<Panel.Body
								className={setClassNames([
									prefs.panelBodyStyle
								])}
							>
								<div
									className={`d-flex ${bodyWrapperClass} pl-3 pl-md-5`}
								>
									<div className={bodyContentClass}>
										{labelAsHTML(labels.get(content))}
									</div>
									{imageSrc && (
										<div className={bodyImageClass}>
											<img
												src={imageSrc}
												alt={labels.get(imageAriaLabel)}
											/>
										</div>
									)}
								</div>
							</Panel.Body>
						</Panel.Collapse>
					</Panel>
				</PanelGroup>
			</div>
		</div>
	);
};

AccordionItem.propTypes = {
	item: PropTypes.shape({
		title: PropTypes.string.isRequired,
		content: PropTypes.string.isRequired,
		imageSrc: PropTypes.string,
		imageAlignment: PropTypes.string,
		imageAriaLabel: PropTypes.string
	}).isRequired,
	iconCollapse: PropTypes.string.isRequired,
	iconExpand: PropTypes.string.isRequired,
	showExpandedFirstItemByDefault: PropTypes.bool.isRequired,
	defaultExpandIcon: PropTypes.bool.isRequired,
	defaultCollapseIcon: PropTypes.bool.isRequired
};

export default AccordionItem;
